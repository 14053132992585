import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import { imageBG } from '../../styles/utilities/elements';
import ImageLoader from './ImageLoader';
import { breakpoints } from '../../styles/utilities/settings';

const BackgroundImage = ({ hero, logo, alt, children, className, src }) => (
  <VisibilitySensor partialVisibility>
    {({ isVisible }) => (
      <SBackgroundImage hero={hero} logo={logo} className={className}>
        <ImageLoader src={src} alt={alt} visible={isVisible} />
        {children}
      </SBackgroundImage>
    )}
  </VisibilitySensor>
);

export default BackgroundImage;

export const SBackgroundImage = styled.div`
  ${imageBG};

  ${({ hero, logo }) =>
    hero &&
    !logo &&
    `
    @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
      padding: 0;
    }

    > picture,
    img {
      @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
        position: relative !important;
        margin: 0;
        top: 0;
        left: 0;
        transform: none;
        padding: 0 !important;
        height: auto !important;
      }
    }
  `}
`;
