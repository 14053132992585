import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import imageUrlFor, { buildImageObj } from '../js/imageUrlFor';
import RichText from '../components/contentBlocks/RichText';
import { colors, misc, breakpoints } from '../styles/utilities/settings';
import BackgroundImage, {
  SBackgroundImage,
} from '../components/helpers/BackgroundImage';

const Corn = ({
  pageContext: { related },
  data: {
    corn: {
      title,
      metaDescription,
      _rawSlug,
      _rawBody,
      _rawImages,
      _rawAttribute,
    },
  },
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <Layout
      title={title}
      description={metaDescription}
      path={_rawSlug.current}
      pageImage={
        _rawImages[0]
          ? imageUrlFor(buildImageObj(_rawImages[0]))
              .width(600)
              .quality(90)
              .url()
          : null
      }
    >
      <SCorn>
        <div>
          <div className="inner">
            <h1>{title}</h1>
            <RichText content={{ copy: _rawBody }} noWrapper />
            <Attributes>
              {_rawAttribute.rows.map((row) =>
                row.cells.map(
                  (cell, index) => cell && <div key={cell + index}>{cell}</div>
                )
              )}
            </Attributes>
          </div>
        </div>
        <div>
          <BackgroundImage
            key={_rawImages[activeIndex]._key}
            src={_rawImages[activeIndex]}
            visible
            alt={`${title} ${activeIndex + 1}`}
          />
          <Controls>
            <div>
              {_rawImages.length > 1 && (
                <>
                  <p>{`Image ${activeIndex + 1} of ${_rawImages.length}`}</p>
                  <a
                    href="#"
                    disabled={activeIndex === 0}
                    onClick={(e) => {
                      e.preventDefault();
                      if (activeIndex !== 0) {
                        setActiveIndex(activeIndex - 1);
                      }
                    }}
                  >
                    <small>{'<<'}</small>
                    Prev
                  </a>
                  <a
                    href="#"
                    disabled={activeIndex + 1 === _rawImages.length}
                    onClick={(e) => {
                      e.preventDefault();
                      if (activeIndex + 1 !== _rawImages.length) {
                        setActiveIndex(activeIndex + 1);
                      }
                    }}
                  >
                    Next
                    <small>{'>>'}</small>
                  </a>
                </>
              )}
            </div>
            <nav>
              {related.map((link) => (
                <Link key={link._id} to={link.slug}>
                  {link.title.replace('Blue corn', '')}
                </Link>
              ))}
            </nav>
          </Controls>
        </div>
      </SCorn>
    </Layout>
  );
};

export default Corn;

export const query = graphql`
  query CornQuery($id: String!) {
    corn: sanityCorn(_id: { eq: $id }) {
      title
      _rawSlug
      _rawImages
      _rawBody
      _rawAttribute
      metaDescription
    }
  }
`;

const Controls = styled.div`
  width: 100%;
  padding: 5px 0;
  font-size: 15px;

  @media screen and (min-width: ${breakpoints.ipadPort}px) {
    max-width: ${breakpoints.pageWidth / 1.2}px;
  }

  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  a {
    color: ${colors.gray};
    text-decoration: none;
    display: block;
    padding: 10px 10px 10px 0;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  small {
    font-size: 10px;
    vertical-align: middle;
    margin: 0 5px;
  }

  div {
    flex-wrap: wrap;
    display: flex;

    @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
      padding-left: 15px;
    }

    p {
      width: 100%;
      margin: 0;
      color: ${colors.gray};
    }
  }

  nav,
  div {
    display: flex;
  }

  nav {
    padding-right: 15px;

    @media screen and (max-width: ${breakpoints.mobile - 1}px) {
      position: absolute;
      bottom: 100%;
      left: 0;
    }

    a {
      padding: 10px 0 10px 10px;

      &[aria-current='page'] {
        font-weight: bold;

        &:hover {
          color: ${colors.gray};
          cursor: default;
        }
      }

      + a {
        &:before {
          content: '|';
          font-weight: 100;
          padding-right: 10px;
        }
      }

      &:hover {
        &:before {
          color: ${colors.gray};
        }
      }
    }
  }
`;

const Attributes = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 15px;

  @media screen and (min-width: ${breakpoints.mobile}px) {
    display: flex;
    flex-wrap: wrap;
  }

  > div {
    @media screen and (min-width: ${breakpoints.mobile}px) {
      padding: 10px;
      border-bottom: 1px solid ${colors.gray};
    }

    &:nth-child(2n - 1) {
      color: ${colors.red};

      @media screen and (min-width: ${breakpoints.mobile}px) {
        width: 180px;
      }
    }

    &:nth-child(2n) {
      @media screen and (min-width: ${breakpoints.mobile}px) {
        width: calc(100% - 180px);
        border-left: 1px solid ${colors.gray};
      }

      @media screen and (max-width: ${breakpoints.mobile - 1}px) {
        border-bottom: 1px solid ${colors.gray};
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
    }

    &:nth-last-child(1),
    &:nth-last-child(2) {
      border-bottom: 0;
    }
  }
`;

const SCorn = styled.div`
  padding: ${misc.sectionMobileMargin}px 0 ${misc.sectionMargin}px;

  @media screen and (min-width: ${breakpoints.ipadLand}px) {
    display: flex;
    flex-wrap: wrap;
  }

  h1 {
    width: 100%;
    color: ${colors.darkBlue};
  }

  > div {
    @media screen and (min-width: ${breakpoints.ipadLand}px) {
      width: 50%;
    }

    .inner {
      margin: 0 0 0 auto;
      padding: 0 15px;

      @media screen and (min-width: ${breakpoints.ipadLand}px) {
        padding: 0 100px 0 30px;
        max-width: 700px;
      }
    }

    &:nth-child(2) {
      @media screen and (min-width: ${breakpoints.ipadPort}px) {
        padding-top: 35px;
      }

      @media screen and (max-width: ${breakpoints.mobile - 1}px) {
        position: relative;
      }
    }
  }

  ${SBackgroundImage} {
    @media screen and (min-width: ${breakpoints.ipadLand}px) {
      width: 100%;
      height: 0;
      padding-bottom: 60%;
    }

    @media screen and (max-width: ${breakpoints.ipadLand - 1}px) {
      padding-bottom: 60%;
    }
  }

  ${Attributes} {
    margin-top: 30px;
    padding-bottom: 50px;
    @media screen and (min-width: ${breakpoints.mobile}px) {
      margin-top: 50px;
    }
  }
`;
